/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import EntityName from 'components/Globals/EntityName';
import { RenderEntityImage } from 'components/Productions/Display/QuickView/renderers/ProductionComponents';

import { castCrewDates } from 'utils/productions';
import useAppContext from 'utils/hooks/useAppContext';
import usePageContext from 'utils/hooks/usePageContext';

import { TP, ENTITY_TYPE, PAYWALL_TYPES, TRACK_SLUG_IMPRESSION } from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './CastCrewList.module.scss';

const ContributorInfo = ({ data, isCancelled, openInNewTab, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();
  const { setPaywallType } = useAppContext();

  const formattedCastCrewDates = useMemo(() => {
    if (data?.showPerformanceDates) {
      return castCrewDates(data?.performances);
    }

    return null;
  }, [data]);

  const { linkProps } = useMemo(
    () => ({
      linkProps: data?.isLinkable
        ? navigate.getLinkProps({ entity: data?.entity, entityType: data?.entityType })
        : null,
    }),
    [navigate, data],
  );

  const onClickHandler = useCallback(() => {
    if (data?.entity?.id === -1) {
      setPaywallType(PAYWALL_TYPES.ARCHIVED_WALL); // archive modal paywall
    } else if (linkProps) {
      if (openInNewTab) {
        window.open(linkProps.url, '_blank');
      } else {
        navigate.to(linkProps);
      }
    }
  }, [data, navigate, linkProps, openInNewTab, setPaywallType]);

  return (
    <div
      className={classnames(classes.crewDetails, {
        [classes.archived]: data?.entity?.id === -1,
        'paywall-content': data?.entity?.id === -1,
        [classes.nonLinkable]: !data?.isLinkable,
      })}
      onClick={onClickHandler}
    >
      <RenderEntityImage
        entity={data}
        size={42}
        styles={{ root: classes.crewDetails__image, text: classes.imageLetters }}
        textSize={16}
      />
      <div className={classes.crewDetails__info}>
        <div className={classnames(classes.titleLink, { [classes.textOnly]: !data.isLinkable })}>
          <LinkButton
            variant="native"
            {...linkProps}
            target={openInNewTab ? '_blank' : '_self'}
            preventDefault
            disableUnderline
            isLink
            trackingData={{
              ...trackingData,
              entity: data?.entity?.id,
              entityName: data?.entity?.name,
              entityType: data?.entityType,
            }}
          >
            <Typography variant="p" type="medium" className={classes.titleLink_title}>
              <EntityName
                entityType={data?.entityType}
                entity={data?.entity}
                eventType={
                  data?.entityType === ENTITY_TYPE.ARTIST
                    ? TRACK_SLUG_IMPRESSION(ENTITY_TYPE.ARTIST)
                    : TRACK_SLUG_IMPRESSION(ENTITY_TYPE.ORGANIZATION)
                }
                trackEntityType={data?.entityType}
                trackingData={trackingData}
                isRaw
              />
            </Typography>
          </LinkButton>
          {data.isLinkable && <SpriteIcon icon="chevron_right" className={classes.titleLink_icon} />}
        </div>
        {data?.isCover && (
          <Typography variant="p" size="12" className={classes.crewDetails__info_subtitle}>
            ({t(`${TP}.FN_COVER`)})
          </Typography>
        )}
        {formattedCastCrewDates && (
          <Typography
            color="secondary"
            variant="p"
            weight="regular"
            blur={data?.entity?.id === -1}
            size="12"
            className={classnames(classes.crewDetails__info_dates, { [classes.cancelled]: !!isCancelled })}
            inline
          >
            ({formattedCastCrewDates})
          </Typography>
        )}
      </div>
    </div>
  );
};

const GroupedContributor = ({ title, subTitle, data, isCancelled, openInNewTab, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  return (
    <>
      {data?.map((contributor, index) => (
        <div key={title} className={classes.castCrewContent}>
          <div className={classes.title}>
            {index === 0 && (
              <>
                {title && (
                  <Typography variant="p" color="secondary">
                    {t(title)}
                  </Typography>
                )}
                {subTitle && (
                  <Typography variant="p" color="secondary">
                    ({t(subTitle)})
                  </Typography>
                )}
              </>
            )}
          </div>
          <ContributorInfo
            data={contributor}
            isCancelled={isCancelled}
            openInNewTab={openInNewTab}
            trackingData={trackingData}
          />
        </div>
      ))}
    </>
  );
};

const CastCrewList = ({ listTitle, data, production, openInNewTab = false, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');

  return (
    <>
      {listTitle && (
        <Typography variant="h3" type="medium" className={classes.titleType}>
          {t(listTitle)}
        </Typography>
      )}
      <ul className={classes.listItems}>
        {data?.map(({ title, ...rest }) => (
          <li className={classes.listItems__item} key={title}>
            <GroupedContributor
              title={title}
              {...rest}
              isCancelled={production?.isCancelled}
              openInNewTab={openInNewTab}
              trackingData={trackingData}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default CastCrewList;
