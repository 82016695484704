import React from 'react';

import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';

import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';
import { TP, ENTITY_TYPE } from 'constants/index';

import classes from './DrawerHeader.module.scss';

const DrawerHeader = ({ production, title }) => {
  const { t } = useTranslation('NS_CASTING_TOOL');
  const { navigate } = usePageContext();
  const linkProps = navigate.getLinkProps({ entity: production, entityType: ENTITY_TYPE.PRODUCTION });

  return (
    <div className={classes.header}>
      <Typography size="16" weight="medium">
        {title || t(`${TP}.FN_CASTING_TOOL_QUICK_PREVIEW_TITLE`)}
      </Typography>
      <LinkButton
        styles={{ root: classes.btn }}
        rightIcon={<SpriteIcon icon="chevron_right" />}
        isLink
        {...linkProps}
        target="_blank"
      >
        <Typography size="12" color="white">
          {t(`${TP}.FN_CASTING_TOOL_QUICK_PREVIEW_HEADER_BTN_LABEL`)}
        </Typography>
      </LinkButton>
    </div>
  );
};

export default DrawerHeader;
