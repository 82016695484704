/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import Modal from 'components/uiLibrary/Modal';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import useTracking from 'components/Globals/Analytics';

import { ENTITY_TYPE, TP } from 'constants/index';
import { getProductionWorks } from 'utils/productions';
import { useTranslation } from 'src/i18n';
import { COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import EntityName from 'components/Globals/EntityName';
import classes from './WorkSelectDropDown.module.scss';

const Tags = ({ workStageTypes }) => (
  <div className={classes.tags}>
    {workStageTypes?.map((item, index) => (
      <div className={classes.tag} key={item?.id}>
        <Typography className={classes.workTag} size={12} color="secondary">
          {item?.workType}
        </Typography>
        <Typography className={classes.stageTag} size={12} color="secondary">
          {item?.stagingType}
        </Typography>
        {workStageTypes?.length - 1 !== index && <Typography color="secondary">{' | '}</Typography>}
      </div>
    ))}
  </div>
);

const WorksList = ({ works, handleOnChange, trackingData }) => (
  <div className={classes.worksList}>
    {works?.map(work => {
      const { workStageTypes } = work || {};
      return (
        <div onClick={() => handleOnChange(work)} className={classes.worksList__item}>
          <div>
            <EntityName
              entity={work}
              entityType={ENTITY_TYPE.WORK}
              typographyProps={{
                work: {
                  weight: 'medium',
                },
                translation: {
                  size: 14,
                },
                composer: {
                  size: 14,
                },
              }}
              withComposers
              onlyShortName
              trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.MODAL }}
            />
            {workStageTypes?.length > 0 && <Tags workStageTypes={workStageTypes} />}
          </div>
          <div>
            <SpriteIcon icon="chevron_right" size={18} className={classes.workDropDown__expandIcon} />
          </div>
        </div>
      );
    })}
  </div>
);

const WORK_SELECT_DROPDOWN_CONTAINER_ID = 'work-select-dropdown-container';

const WorkSelectDropDown = ({ works, onChange, trackingData }) => {
  const track = useTracking();
  const workList = getProductionWorks(works);
  const [selectedWork, setSelectedWork] = useState(workList?.[0] || {});
  const { t } = useTranslation('NS_DISPLAY_V4');
  const [openModal, setOpenModal] = useState(false);
  const trackingDataWithComponent = {
    ...trackingData,
    component: trackingData?.component || COMPONENTS.WORK_SELECT_DROPDOWN,
  };

  const onCloseModal = () => setOpenModal(false);

  const handleOnChange = work => {
    setSelectedWork(work);
    onChange(work);
    onCloseModal();
    track.click({
      ...trackingDataWithComponent,
      subComponent: SUB_COMPONENTS.MODAL,
      entityId: work?.id,
      entityName: work?.name,
      entityType: ENTITY_TYPE.WORK,
    });
  };

  return (
    <div id={WORK_SELECT_DROPDOWN_CONTAINER_ID}>
      <div onClick={() => setOpenModal(true)} className={classes.workDropDown}>
        <Typography color="secondary">{t(`${TP}.FN_WORK_TYPE`)}</Typography>
        <EntityName
          entity={selectedWork}
          entityType={ENTITY_TYPE.WORK}
          typographyProps={{
            work: {
              color: 'link',
              className: classes.workDropDown__selectedWork,
            },
            translation: {
              color: 'link',
            },
            composers: {
              color: 'link',
            },
          }}
          withComposers
          onlyShortName
          trackingData={trackingDataWithComponent}
        />
        <SpriteIcon icon="expand_more" size={18} className={classes.workDropDown__expandIcon} />
      </div>
      <Modal
        isOpen={openModal}
        onClose={onCloseModal}
        title={t(`${TP}.FN_WORK_TYPE`)}
        wrapperId={WORK_SELECT_DROPDOWN_CONTAINER_ID}
        allowMobileDrawer
      >
        <WorksList works={workList} handleOnChange={handleOnChange} trackingData={trackingDataWithComponent} />
      </Modal>
    </div>
  );
};

export default WorkSelectDropDown;
