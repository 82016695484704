import React, { useState, useMemo } from 'react';

import SectionBlock from 'components/Globals/SectionBlock';
import Typography from 'components/uiLibrary/Typography';

// eslint-disable-next-line import/no-cycle
import { getProductionCredits } from 'utils/productions';
import usePageContext from 'utils/hooks/usePageContext';
import { createDate } from 'utils/date';

import { TP, ENTITY_TYPE, ENTITY_MAIN_TABS, DATE_FORMATS } from 'constants/index';
import { useTranslation } from 'src/i18n';
import { getProgramLabel } from 'utils/common';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import CastCrewList from './CastCrewList';
import WorkSelectDropDown from './WorkSelectDropDown';
import classes from './CastCrewEnsembles.module.scss';

const trackingData = {
  section: SECTIONS.PERFORMANCE_CREDITS,
};
const CastCrewEnsembles = ({ entity: production, sectionProps = {}, openInNewTab }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const [workId, setWorkId] = useState(() => production?.productionWorks?.[0]?.work?.id);
  const { context, navigate } = usePageContext();

  const credits = useMemo(() => getProductionCredits(production, { workId, performanceDate: context?.date?.[0]?.id }), [
    production,
    workId,
    context?.date,
  ]);

  const primaryCredits = useMemo(() => {
    const { producers, directors, conductors } = credits;

    return [...(producers ? [producers] : []), ...(conductors ? [conductors] : []), ...(directors ? [directors] : [])];
  }, [credits]);

  const castCrewLinkProps = useMemo(
    () =>
      navigate.getLinkProps({
        entityType: ENTITY_TYPE.PRODUCTION,
        entity: production,
        path: ENTITY_MAIN_TABS.CAST_CREW,
      }),
    [production, navigate],
  );

  let workList = production?.productionWorks ?? [];

  if (production?.isProgramNotAnnounced) {
    workList = [
      ...workList,
      {
        id: -1,
        work: { id: -1, name: t(getProgramLabel(production, workList?.length)) },
      },
    ];
  }

  return (
    <SectionBlock
      title={
        <>
          {t(`${TP}.FN_CREDITS`)}
          <Typography weight="regular">{` (${t(`${TP}.CAST_CREW`)} )`}</Typography>
        </>
      }
      enableDivider
      linkProps={castCrewLinkProps}
      className={classes.root}
      seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
      {...sectionProps}
    >
      {context?.date?.[0]?.id && (
        <Typography className={classes.performanceViewInfo}>{`${t(`${TP}.FN_VIEWING_CAST_CREW_FOR`)} ${createDate(
          context?.date?.[0]?.id,
          DATE_FORMATS.FULL_DATE,
        ).format(DATE_FORMATS.DATE_LABEL)}`}</Typography>
      )}
      {workList?.length > 1 && (
        <div className={classes.worksSelector}>
          <WorkSelectDropDown works={workList} onChange={({ id }) => setWorkId(id)} trackingData={trackingData} />
        </div>
      )}
      {primaryCredits?.length > 0 && (
        <CastCrewList
          data={primaryCredits}
          production={production}
          openInNewTab={openInNewTab}
          trackingData={{
            ...trackingData,
            component: COMPONENTS.PRIMARY_CREDITS,
          }}
        />
      )}
      {credits.cast?.length > 0 && (
        <CastCrewList
          listTitle={`${TP}.FN_CAST`}
          data={credits.cast}
          production={production}
          openInNewTab={openInNewTab}
          trackingData={{
            ...trackingData,
            component: COMPONENTS.CAST,
          }}
        />
      )}
      {credits.instrumentalists?.length > 0 && (
        <CastCrewList
          listTitle={`${TP}.FN_INSTRUMENTATION`}
          data={credits.instrumentalists}
          production={production}
          openInNewTab={openInNewTab}
          trackingData={{
            ...trackingData,
            component: COMPONENTS.INSTRUMENTALISTS,
          }}
        />
      )}
      {credits.crew?.length > 0 && (
        <CastCrewList
          listTitle={`${TP}.FN_CREW_TITLE`}
          data={credits.crew}
          production={production}
          openInNewTab={openInNewTab}
          trackingData={{
            ...trackingData,
            component: COMPONENTS.CREW,
          }}
        />
      )}
      {credits.ensembles?.length > 0 && (
        <CastCrewList
          listTitle={`${TP}.FN_ENSEMBLE`}
          data={credits.ensembles}
          production={production}
          trackingData={{
            ...trackingData,
            component: COMPONENTS.ENSEMBLES,
          }}
        />
      )}
      {credits.coProducers && (
        <CastCrewList
          data={[credits.coProducers]}
          listTitle={`${TP}.FN_CO_PRODUCER`}
          production={production}
          openInNewTab={openInNewTab}
          trackingData={{
            ...trackingData,
            component: COMPONENTS.CO_PRODUCER,
          }}
        />
      )}
    </SectionBlock>
  );
};

export default CastCrewEnsembles;
