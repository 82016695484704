import React from 'react';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import classes from './LangAndSubTitles.module.scss';

const LangAndSubTitles = ({ production, size = '14', showSlug = false }) => (
  <>
    {(production?.subtitles?.length > 0 || production?.languages?.length > 0) && (
      <div className={classes.langSubtitles}>
        {production?.languages?.length > 0 && (
          <div className={classes.languages}>
            <SpriteIcon icon="world" size={size} className={classes.icon} />
            {production?.languages?.map((subtitle, langIndex) => (
              <Typography size={size} color="secondary" key={subtitle?.id}>
                {subtitle?.name}
                {production?.languages?.length - 1 !== langIndex ? ', ' : ''}
              </Typography>
            ))}
          </div>
        )}
        {production?.subtitles?.length > 0 && (
          <div className={classes.subtitles}>
            <SpriteIcon icon="closed_caption" size={12} className={classes.icon} />
            {production?.subtitles?.map((subtitle, langIndex) => (
              <Typography size={12} color="secondary" key={subtitle?.id}>
                {showSlug ? subtitle?.slug : subtitle?.name}
                {production?.subtitles?.length - 1 !== langIndex ? '/' : ''}
              </Typography>
            ))}
          </div>
        )}
      </div>
    )}
  </>
);

export default LangAndSubTitles;
