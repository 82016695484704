/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
// eslint-disable-next-line import/no-cycle
import { useQuery } from 'utils/react-query';

import queries from 'containers/Productions/queries';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Drawer from 'components/uiLibrary/Drawer';
import Modal from 'components/uiLibrary/Modal';

import { useTranslation } from 'src/i18n';
import { useProductionSummary } from 'utils/productions';
import { TP } from 'constants/index';

import ContentHeader from './ContentHeader';
import CastCrewEnsembles from '../CastCrewEnsembles';
import LocationAndDates from '../ProSlug/LocationAndDates';
import DrawerHeader from './DrawerHeader';
import ProductionWatchOptions from '../ProductionWatchOptions';
import ProductionStubWorks from '../ProductionStubInfo/ProductionStubWorks';

import classes from './QuickPreview.module.scss';

const QuickPreview = ({ productionId, entity, isOpen, setIsOpen, performanceId }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const isMobile = useMediaQuery('(max-width: 900px)');
  const { data: production, isLoading } = useQuery(
    queries.getProductionDetails({
      id: productionId,
      queryConfig: { enabled: !!productionId },
    }),
  );

  const performanceDate = useMemo(() => {
    if (!production?.id) {
      return {};
    }

    return production?.performances?.filter(item => item?.id === performanceId)[0]?.startDate;
  }, [production, performanceId]);

  if (isLoading || !production?.id) {
    return null;
  }

  const Children = () => (
    <div className={classes.quickPreview}>
      {!isMobile && (
        <div className={classes.quickPreview__header}>
          <SpriteIcon
            icon="close"
            size={30}
            className={classes.quickPreview__header_close}
            onClick={() => setIsOpen(false)}
          />
          <DrawerHeader production={production} title={t(`${TP}.PROFILE_MANAGE_PREVIEW`)} />
        </div>
      )}
      <QuickPreviewContent production={production} entity={entity} performanceDate={performanceDate} />
    </div>
  );

  if (isMobile) {
    return (
      <Drawer
        header={<DrawerHeader production={production} title={t(`${TP}.PROFILE_MANAGE_PREVIEW`)} />}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Children />
      </Drawer>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      styles={{
        modalRoot: classes.modalRoot,
        modalContainer: classes.modalContainer,
        modalContent: classes.modalContent,
      }}
      allowMobileDrawer
    >
      <Children />
    </Modal>
  );
};

export const QuickPreviewContent = ({ production, entity, openInNewTab, performanceDate }) => {
  const productionSummary = useProductionSummary(production);

  return (
    <div className={classes.parent}>
      <ContentHeader production={production} />
      <LocationAndDates
        isExpanded
        production={production}
        profile={entity}
        showTitle
        hideProductionPeriod
        showCollapsedAlways={false}
      />
      {productionSummary && <ProductionStubWorks production={productionSummary} showProductionName />}
      <ProductionWatchOptions entity={production} />
      <CastCrewEnsembles
        entity={production}
        sectionProps={{ enableSeeAll: false }}
        openInNewTab={openInNewTab}
        performanceDate={performanceDate}
      />
    </div>
  );
};

export default QuickPreview;
