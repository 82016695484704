import React from 'react';

import Typography from 'components/uiLibrary/Typography';
import SeparatorList from 'components/uiLibrary/SeparatorList';

import { useTranslation } from 'src/i18n';
import { getProductionStubTags, useProductionSummary } from 'utils/productions';
import { ENTITY_TYPE, TP, TRACK_SLUG_IMPRESSION } from 'constants/index';
import { getWorkComposers } from 'utils/works';

import EntityName from 'components/Globals/EntityName';
import LangAndSubTitles from '../../ProductionStubInfo/LangAndSubTitles';
import classes from './ContentHeader.module.scss';

const ContentHeader = ({ production }) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const productionSummary = useProductionSummary(production);
  const { mainTags = [] } = getProductionStubTags(production) || {};
  const composers = getWorkComposers(production?.productionWorks?.[0]?.work, true, true);

  return (
    <div className={classes.header}>
      <Typography size="14" color="secondary">
        {productionSummary?.period}
      </Typography>
      {production?.name ? (
        <Typography size="18" weight="bold">
          <EntityName
            entity={production}
            entityType={ENTITY_TYPE.PRODUCTION}
            eventType={TRACK_SLUG_IMPRESSION(ENTITY_TYPE.PRODUCTION)}
            isRaw
          >
            {production?.name}
          </EntityName>
        </Typography>
      ) : (
        <EntityName
          entity={production?.productionWorks?.[0]?.work}
          entityType={ENTITY_TYPE.WORK}
          eventType={TRACK_SLUG_IMPRESSION(ENTITY_TYPE.WORK)}
          typographyProps={{
            work: {
              size: '18',
              weight: 'bold',
            },
            translation: {
              size: '18',
              weight: 'bold',
            },
          }}
        />
      )}
      <div className={classes.headerDetails}>
        <Typography variant="p" className={classes.composer} color="secondary" italic>
          <SeparatorList data={composers} />
        </Typography>

        {productionSummary?.works?.length > 1 && (
          <Typography className={classes.musicalWorkCount} size="12" weight="bold">
            {t(`${TP}.FN_PROGRAM_COUNT_WORKS`, { count: productionSummary?.works?.length })}
          </Typography>
        )}
        <LangAndSubTitles production={production} size="12" showSlug />
        {productionSummary?.duration && (
          <Typography className={classes.duration} size="12" color="secondary" truncate>
            {productionSummary?.duration}
          </Typography>
        )}
        {mainTags?.length > 0 &&
          mainTags?.map(tag => (
            <Typography size="12" color="secondary">
              {t(tag?.label)}
            </Typography>
          ))}
      </div>
    </div>
  );
};

export default ContentHeader;
